import { Link } from "react-router-dom";
import {
  faPoundSign,
  faBed,
  faUsers,
  faChartLine,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTokenStore from "../../store/store";
import { numberFormat } from "../../utils/properties";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PropertyCardProps {
  id: string;
  discount: string;
  available: Boolean;
  image: string;
  streetName: string;
  price: string;
  bedrooms: string;
  value: string;
  rent: string;
  netCashFlow: string;
  grossYield: string;
  strategy: string;
  discountLabel?: string;
}

const PropertyCard = ({
  id,
  discount,
  available,
  image,
  streetName,
  price,
  bedrooms,
  value,
  rent,
  netCashFlow,
  grossYield,
  strategy,
  discountLabel,
}: PropertyCardProps) => {
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  return (
    <Link
      to={getToken ? `/listing/${id}` : `/login`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="max-w-7xl m-auto mb-5">
        <div className="grid">
          <div className="border border-gray-200 rounded-xl p-4 relative shadow-md hover:shadow-xl hover:transition hover:duration-150 hover:ease-in-out">
            
            {/* Discount Label or Default Discount */}
            {discountLabel && discountLabel !== "" ? (
              <div className="absolute text-center left-0 right-0 mt-7">
                <button
                  className="py-1 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                    style={{
                      backgroundColor: getThemes?.color1,
                      borderColor: getThemes.color2,
                      borderWidth: 2, // Adjust the border width as needed
                      borderStyle: 'solid', // You can use other styles like dashed or dotted
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to create depth
                    }}
                >
                  {discountLabel}
                </button>
              </div>
            ) : (
              <div className="absolute text-center left-0 right-0 mt-7">
                <button
                  className="bg-gradient-to-br from-primarycolor to-secondarycolor py-1 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                  style={{
                    background: `linear-gradient(to bottom,${getThemes?.color1},${getThemes?.color2})`,
                  }}
                >
                  {discount}% Discount
                </button>
              </div>
            )}

            {/* Property Image */}
            <div className="w-full">
              <LazyLoadImage
                src={image}
                width={600}
                height={400}
                className="rounded-xl h-[250px] w-full"
                alt="Property Image"
              />
            </div>
            
            {/* Display "Reserved" button for properties that are available */}
            {available && (
              <div className="absolute text-center left-0 right-0 -mt-5">
                <button
                  className="py-2 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                  style={{
                    backgroundColor: getThemes?.color1,
                    borderColor: getThemes.color2,
                    borderWidth: 2, // Adjust the border width as needed
                    borderStyle: 'solid', // You can use other styles like dashed or dotted
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to create depth
                  }}
                >
                  Reserved
                </button>
              </div>
            )}
            <div className="pt-7">
              <h3 className="font-roboto font-medium text-lg hover:underline hover:text-primarycolor cursor-pointer">
                {streetName}
              </h3>
              <div className="text-left text-sm font-roboto font-medium pt-3 text-black-373933"></div>
                <ul className="grid grid-cols-2 text-left">
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faPoundSign} className="" />
                    Price £{numberFormat(price as unknown as number)}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faBed} />
                    {bedrooms}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faPoundSign} className="" />
                    Zoopla Valuation £{numberFormat(value as unknown as number)}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faUsers} className="" />
                    Discount {discount}%
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faUsers} className="" />
                    Rent £{numberFormat(rent as unknown as number)}
                  </li>

                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faChartLine} className="" />
                    Net Cashflow £
                    {numberFormat(netCashFlow as unknown as number)}
                  </li>
                  <li className="py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faChartLine} className="" />
                    Yield {grossYield}%
                  </li>

                  {strategy != "" && (
                    <li className="py-2 gap-2 flex items-center font-bold">
                      <FontAwesomeIcon icon={faMoneyBillAlt} className="" />
                      {strategy}
                    </li>
                  )}
                </ul>
                </div>
            </div>
          </div>
        </div>
    </Link>
  );
};

export default PropertyCard;
