import { useEffect, useState } from "react";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';

interface Props {
  setIsModalOpen: (value: boolean) => void;
  selectedProductIdSet: (value: string) => void;
}

interface CustomCardProps {
  title: string;
  description: string;
  logo: string; // Image URL
  bulletPoints: string[]; // Array of bullet points
  onOpenModal: (productId: string) => void;
  productId: string;
  isFirst: boolean; // Indicates whether it's the first card
  isLast: boolean; // Indicates whether it's the last card
}

// Define the styles with correct typing
const arrowStyles: CSSProperties = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  color: 'white',
  fontSize: '25px', // Larger size
  zIndex: 25,
  width: '25px', // Ensures area is large enough for easy clicking
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,0.7)', // Semi-transparent black background
  borderRadius: '50%',
  boxShadow: '0 2px 5px rgba(0,0,0,0.8)' // Adds shadow for 3D effect
};

const CAROUSEL_INTERVAL = 15000; // Change the visible cards every 15 seconds

const ProductIds = {
  productManagement: "661f66e260e8287c128c29fe",
  mortgageBroker: "661f66e360e8287c128c2a20",
  conveyancing: "661f66e460e8287c128c2a28",
  propertyRefurbishment: "661f66e460e8287c128c2a2a",
  sellAProperty: "661f66e460e8287c128c2a2c",
  dealSourcing: "661f66e460e8287c128c2a2e",
  stampDutyReclaim: "6620cfe2e2142a237355affd",
};

const CUSTOM_CARDS = [
  {
    title: "Property Management",
    description: "This is card 1",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/data-initiative-abstract-concept-vector-illustration-open-platform-information-initiative-metadata-study-data-driven-startup-research-development-privacy-policy-abstract-metaphor_335657-2513.avif",
    bulletPoints: ["10% Management Fee", "Nationwide Coverage", "Technology Driven Property Management", "Great Service For a Low Cost"],
    productId: ProductIds.productManagement,
  },
  {
    title: "Mortgage Broker",
    description: "This is card 2",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/access-control-system-abstract-concept_335657-3180.avif",
    bulletPoints: ["Whole Market Broker", "Responsive & Easy To Talk To", "Can Help on Small, Medium and Large Properties", "Quality at Affordable Rates"],
    productId: ProductIds.mortgageBroker,
  },
  {
    title: "Conveyancing",
    description: "This is card 3",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/human-relations-abstract-concept-vector-illustration-career-success-publicrelations-businessman-handshake-team-building-cooperation-participation-human-resources-company-abstract-metaphor_335657-1421.avif",
    bulletPoints: ["Panel of Solicitors Available", "Dual Representation with Most Lenders", "Proactive & Solution Focused", "Low Cost Fees"],
    productId: ProductIds.conveyancing,
  },
  {
    title: "Property Refurbishment",
    description: "This is card 4",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/website-maintenance-abstract-concept-vector-illustration-website-service-webpage-seo-maintenance-web-design-corporate-site-professional-support-security-analysis-update-abstract-metaphor_335657-2295.avif",
    bulletPoints: ["Nationwide Coverage", "Very Fairly Priced", "Project Management Included", "Specialise in Resolving Abandoned Projects"],
    productId: ProductIds.propertyRefurbishment,
  },
  {
    title: "Sell a Property",
    description: "This is card 5",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/coworkers-cartoon-characters-effective-collaboration-coworkers-cooperation-teamwork-colleagues-discussing-solution-successful-interaction_335657-2309.avif",
    bulletPoints: ["Commision Based Revenue", "Off Market Property Specialists", "Worldwide Exposure", "Access to Active Buyers Looking For Assets"],
    productId: ProductIds.sellAProperty,
  },
  {
    title: "Deal Sourcing",
    description: "This is card 6",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/chatbot-customer-service-abstract-concept_335657-3037.avif",
    bulletPoints: ["£1500 Minimum Per Property", "Custom Branded Portal", "£300m Worth Of Properties", "Training Available to Optimize Earning Potential"],
    productId: ProductIds.dealSourcing,
  },
  {
    title: "Stamp Duty Reclaim",
    description: "This is card 7",
    logo: "https://whitelabel-uploads.s3.amazonaws.com/Carousel/detached-house-abstract-concept-vector-illustration-single-family-house-stand-alone-household-single-detached-building-individual-land-ownership-unattached-dwelling-unit-abstract-metaphor_335657-1974.jpg",
    bulletPoints: ["Reclaim Stamp Duty", "For Any Asset Purchased (Up To 4 Years Ago)", "Claim For Uninhabitable Property", "100% Success Rate"],
    productId: ProductIds.stampDutyReclaim,
  },
];

// Calculate a random start index for the initial state
const randomStartIndex = Math.floor(Math.random() * CUSTOM_CARDS.length);

// Initialize visibleCards state starting from the random index
const initialVisibleCards = Array.from({ length: 4 }, (_, i) => {
  return CUSTOM_CARDS[(randomStartIndex + i) % CUSTOM_CARDS.length];
});

export default function FeaturedCardsCarousel(props: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(initialVisibleCards);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);
  const { setIsModalOpen, selectedProductIdSet } = props;

  // Check if the screen size is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % CUSTOM_CARDS.length);
    }, CAROUSEL_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setVisibleCards([CUSTOM_CARDS[currentIndex]]);
    } else {
      const endIndex = (currentIndex + 4) % CUSTOM_CARDS.length;
      if (endIndex >= currentIndex) {
        setVisibleCards(CUSTOM_CARDS.slice(currentIndex, endIndex));
      } else {
        setVisibleCards([...CUSTOM_CARDS.slice(currentIndex), ...CUSTOM_CARDS.slice(0, endIndex)]);
      }
    }
  }, [currentIndex, isMobile]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + CUSTOM_CARDS.length) % CUSTOM_CARDS.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % CUSTOM_CARDS.length);
  };

  const CustomCard = ({ title, description, logo, bulletPoints, onOpenModal, productId, isFirst, isLast }: CustomCardProps) => {
    const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);

    return (
      <div className="card grid" style={{ border: "1px solid #ccc", padding: "20px", margin: "5px", marginTop: "15px", borderRadius: "15px", display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", backgroundColor: "white", position: "relative" }}>
       {isFirst && <FontAwesomeIcon icon={faArrowLeft} style={{ ...arrowStyles, position: "absolute", left: "15px", top: "89%", transform: "translateY(-50%)", zIndex: 1 }} onClick={handlePrevClick} />}
      {isLast && <FontAwesomeIcon icon={faArrowRight} style={{ ...arrowStyles, position: "absolute", right: "15px", top: "89%", transform: "translateY(-50%)", zIndex: 1 }} onClick={handleNextClick} />}
        <h2 style={{ fontWeight: "bold" }}>{title}</h2>
        <img src={logo} alt="Logo" style={{ width: "100px", height: "100px", margin: "10px 0" }} />
        <ul style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "20px" }}>
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <button
          onClick={() => onOpenModal(productId)}
          style={{
            backgroundColor: themes.color1,
            color: themes.txtColor === "rgba(255,255,255,1)" ? themes.txtColor : "#FFFFFF",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            marginTop: "30px",
            transition: "background-color 0.3s",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = getThemes?.color2 || "alternateHoverColor";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = getThemes?.color1 || "defaultColor";
          }}>
          Enquire Here
        </button>
      </div>
    );
  };

  return (
    <div className="w-full m-auto pt-4 mb-1">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full">
        {visibleCards.map((card, index) => (
          <div key={index}>
            <CustomCard
              {...card}
              onOpenModal={(productId) => [selectedProductIdSet(productId), setIsModalOpen(true)]}
              isFirst={index === 0}
              isLast={index === visibleCards.length - 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
}