import React, { useRef, useState } from "react";
import Slider, { Settings, CustomArrowProps } from "react-slick";
import "../../../src/App.css"
import { ImagesEntity } from "../../utils/properties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties } from 'react';

interface ImageSliderProps {
  images: ImagesEntity[];

  
}

// Define the styles with correct typing
const arrowStyles: CSSProperties = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  color: 'white',
  fontSize: '25px', // Larger size
  zIndex: 25,
  width: '35px', // Ensures area is large enough for easy clicking
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,0.6)', // Semi-transparent black background
  borderRadius: '50%',
  boxShadow: '0 2px 5px rgba(0,0,0,0.5)' // Adds shadow for 3D effect
};

const PrevArrow: React.FC<CustomArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, ...arrowStyles, left: '15px' }} // Merging provided style with custom style
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </div>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, ...arrowStyles, right: '15px' }} // Merging provided style with custom style
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faArrowRight} />
    </div>
  );
};

const ImageSlider = ({ images }: ImageSliderProps) => {
  const slider1 = useRef<Slider | any>(null);
  const slider2 = useRef<Slider | any>(null);

  const settings1: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    asNavFor: slider2.current,
    arrows: true,
  };

  const settings2 = {
  dots: true,
  infinite: true,
  slidesToShow: images.length > 3 ? 3 : images.length,
  slidesToScroll: 1,
  asNavFor: slider1.current,
  centerMode: true,
  focusOnSelect: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 596,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
};


  const [zoom, setZoom] = useState(false);

  return (
    <>
        <div className={`${zoom ? 'fixed inset-0 p-[130px] z-[99999999]' : 'w-[500px] cursor-zoom-in'}`}>
            {zoom && (
                <div className={`${zoom ? 'fixed inset-0 bg-black-373933 cursor-zoom-out' : 'hidden'}`} onClick={() => setZoom(false)}></div>
            )}
            {zoom && (
                <div className={`${zoom ? 'fixed top-20 right-20' : 'hidden'}`} onClick={() => setZoom(false)}>
                    <FontAwesomeIcon icon={faTimes} className="text-2xl cursor-pointer" />
                </div>
            )}
            <div className="relative">
                <Slider ref={slider1} {...settings1} className={`${zoom ? 'zoom-active' : 'non-zoom-state'}`}>
                    {images.map((ele, index) => (
                        <div key={index} onClick={() => setZoom(true)} style={{ textAlign: 'center' }} className="cursor-zoom-in">
                            <img
                                style={{ maxWidth: '100%', margin: 'auto' }} // Centers image horizontally
                                className={`${zoom ? "lg:h-[800px] w-full object-contain" : "lg:max-h-96 object-cover"}`}
                                src={ele.url || "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"}
                                alt=""
                            />
                        </div>
                    ))}
                </Slider>
                {images.length > 3 && (
                    <Slider ref={slider2} {...settings2} className={`${zoom ? 'zoom-active slider-zoom-active' : 'non-zoom-state'}`}>
                        {images.map((ele, index) => (
                            <div key={index} style={{ textAlign: 'center' }}>
                                <img
                                    style={{ maxWidth: '100%', margin: 'auto' }} // Centers image horizontally
                                    className={`${zoom ? "h-52 w-full object-cover cursor-pointer" : "h-20 w-full object-cover cursor-pointer"}`}
                                    src={ele.url || "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"}
                                    alt=""
                                />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    </>
);

      };

      export default ImageSlider;
