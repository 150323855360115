import axios from "axios";
import { baseUrl } from ".";
import { ResultMessageProps } from "./user";

export interface PropertiesSearch {
  page: Number;
  limit: Number;
  propertyType?: String;
  minPrice?: Number;
  maxPrice?: Number;
  minGrossYield?: Number;
  maxGrossYield?: Number;
  searchKeyword?: String;
}

export interface Filter {
  maxGrossYield: number;
  maxPrice: number;
  minGrossYield: number;
  minPrice: number;
  minBedroom: number;
  maxBedroom: number;
  propertyType: string[];
  strategy: string[];
  regions: string[];
  towns: string[];
}
export interface Pagination {
  total: number;
  next: number;
  prev: number;
}

export interface Theme {
  color1: String;
  color2: String;
  logo: String;
  paymentAccept: Boolean;
}

export interface PropertiesExtraResult {
  results: Property[];
  filter: Filter;
  message: String;
  pagination: Pagination;
  theme: Theme;
}

export interface PropertyExtraResult {
  results: Property;
  message: String;
  theme: Theme;
}

export interface Property {
  propertykeyFeatures?: string[] | null;
  strategy?: string | null;
  currentRent?: number | null;
  images?: ImagesEntity[] | null;
  sizing?: string[] | null;
  wishlist: boolean;
  rentComparable?: string[] | null;
  saleComparable?: string[] | null;
  marketComparable?: null[] | null;
  streetComparable?: null[] | null;
  benefits?: string[] | null;
  refurbishments?: string[] | null;
  nearestStation?: NearestStationEntity[] | null;
  nearestSchool?: NearestSchoolEntity[] | null;
  createdAt: string;
  updatedAt: string;
  _id: string;
  propertyId: string;
  connectedTo: string;
  propertyDescription: string;
  propertyOf: string;
  propertyAddress: PropertyAddress;
  website: string;
  longitude?: null;
  latitude?: null;
  postcode: string;
  lhaRent: number;
  price: number;
  bedrooms: number;
  isListingLive: boolean;
  houseType: string;
  houseSubtype: string;
  localcontact: string;
  houseNumber: string;
  averageRent: number;
  lhaGrossYield?: null;
  lhaNetYield?: null;
  rentGrossYield: number;
  rentNetYield?: null;
  averagePriceValution?: null;
  rentPropertyMacthed?: null;
  fullAddress: string;
  estateAgentEmail: string;
  estateAgentPhone: string;
  estateAgentName: string;
  brandName: string;
  branchName: string;
  discountToMarket: number;
  discountLabel?: string;
  returnToMarket: number;
  listedDate: string;
  tenureType: string;
  username: string;
  userHandle: string;
  agentAddress: string;
  agentPhone: string;
  refurbishmentCost: number;
  epc: string;
  floorsqft: string;
  floodRisk: string;
  offerPrice: number;
  soldStc: boolean;
  reserved: boolean;
  reservationPrice: number;
  propertyFrom: string;
  estimatedValue: number;
  legal: number;
  investment: number;
  occupancy: string;
  depositeProperty: number;
  management: number;
  brokerage: number;
  opex: number;
  mortgage: number;
  mortgageLabel: string;
  netCashflow: number;
  setupFeeValuation: number;
  annualCashflow: number;
  stampDuty: number;
  __v: number;
}
export interface ImagesEntity {
  url: string;
  caption?: null;
  resizedImageUrls: ResizedImageUrls;
}
export interface ResizedImageUrls {
  size135x100: string;
  size476x317: string;
  size656x437: string;
}
export interface NearestStationEntity {
  station: string;
  distance: string;
}
export interface NearestSchoolEntity {
  school: string;
  distance: string;
}
export interface PropertyAddress {
  displayAddress: string;
  countryCode: string;
  deliveryPointId?: null;
  ukCountry: string;
  outcode: string;
  incode: string;
}
export interface EnquiryData {
  name: string;
  phoneNumber: string;
  email: string;
}

export const sendEnquiry = async (
  data: EnquiryData
): Promise<any> => {
  try {
    const response = await axios.post(
      `${baseUrl}/properties/enquiries`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getProperties = async (
  data: PropertiesSearch
): Promise<PropertiesExtraResult> => {
  try {
    const response = await axios.post(
      `${baseUrl}/whitelabel/properties`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    window.location.reload();
    return undefined as any;
  }
};

export const getFeaturedProperties =
  async (): Promise<PropertiesExtraResult> => {
    try {
      const response = await axios.get(
        `${baseUrl}/whitelabel/getFeaturedProperties`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      window.location.reload();
      return undefined as any;
    }
  };

export const getProperty = async (id: string): Promise<PropertyExtraResult> => {
  try {
    const response = await axios.get(
      `${baseUrl}/whitelabel/singleProperty/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined as any;
  }
};

export const checkout = async ({ id, url, token }: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${baseUrl}/whitelabel/checkout`,
      { _id: id, url: url },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${token.replace("B9I9N9456", "")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined as any;
  }
};

interface OrderParamsProps {
  connectedTo: string;
  paymentStatus: string;
  token: string;
}

export const orderProperty = async (
  data: OrderParamsProps
): Promise<ResultMessageProps> => {
  try {
    const response = await axios.post(
      `${baseUrl}/whitelabel/reserve`,
      {
        connectedTo: data.connectedTo,
        paymentStatus: data.paymentStatus,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token.replace("B9I9N9456", "")}`,
        },
      }
    );
    return { status: response.status, message: response.data.message };
  } catch (error: any) {
    console.log(error);
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

export interface HubspotProps {
  email: string;
  firstname: string;
  lastname: string;
  mobilephone: string;
  message: string;
}

export const hubSpotContact = async (
  data: HubspotProps
): Promise<ResultMessageProps> => {
  try {
    const response = await axios.post(
      `${baseUrl}/whitelabel/getintouch`,
      {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        mobilephone: data.mobilephone,
        message: data.message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return { status: response.status, message: response.data.message };
  } catch (error: any) {
    console.log(error);
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

interface MakeAnOfferProps {
  connectedTo: string;
  offer: string;
  propertyLink: string;
  token: string;
}

export const postMakeAnOffer = async ({
  connectedTo,
  offer,
  propertyLink,
  token,
}: MakeAnOfferProps): Promise<ResultMessageProps> => {
  try {
    // console.log(connectedTo, offer, propertyLink, token);
    const response = await axios.post(
      `${baseUrl}/whitelabel/makeoffer`,
      {
        connectedTo,
        offer,
        propertyLink,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.replace("B9I9N9456", "")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined as any;
  }
};

export const numberFormat = (value: number) => {
  let internationalNumberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return internationalNumberFormat.format(value);
};
