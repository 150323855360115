import { useEffect, useState } from "react";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { sendEnquiry } from "../../utils/properties";
import useTokenStore from "../../store/store";
import jwt2json from "../../utils/jwt2json";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productId: string;
  user?: {
    name: string;
    phoneNumber: string;
    email: string;
  };
}
const EMPTY = "";

let nastyFlag = false;

export default function FeatureEnquiryModal(props: Props) {
  const { isOpen, onClose, productId } = props;
  const jwt = useTokenStore((state) => state.token);
  const user = jwt ? jwt2json(jwt) : null;
  const { _id: userId } = user || {};
  const [busy, busySet] = useState<boolean>(false);
  const [name, nameSet] = useState<string>(user?.name || EMPTY);
  const [phoneNumber, phoneNumberSet] = useState<string>(user?.phoneNumber || EMPTY);
  const [email, emailSet] = useState<string>(user?.email || EMPTY);
  const [finished, finishedSet] = useState<boolean>(false);
  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const clearForm = () => {
    busySet(false);
    nameSet(EMPTY);
    phoneNumberSet(EMPTY);
    emailSet(EMPTY);
    finishedSet(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | null = null) => {
    event?.preventDefault();
    busySet(true);

    // Prepare data for sending, e.g., via an API call to your backend
    const data = { name, phoneNumber, email, productId, userId };

    await sendEnquiry(data)
      .then((result: any) => {
        const CLIENT_ERROR = 400;
        const OK = "OK";
        if (result?.response?.status === CLIENT_ERROR) {
          const message = result.response.data.message;
          alert(`AN ERROR OCCURRED\n\n${message}`);
        }
        if (result?.message && result?.message?.toUpperCase() === OK) {
          finishedSet(true);
        }
        busySet(false);
        nastyFlag = false;

        // if there no user navigate to register
        if (user === null) {
          window.location.href = "/register";
        }
      })
      .catch((error: any) => {
        console.error("Error when submitting form", error);
        alert(error);
        busySet(false);
      });
  };

  const updateField = (event: any, setFn: any) => setFn(event.target.value || "");

  useEffect(() => {
    // automatically send any enquiries
    if (isOpen && !nastyFlag && user?.email && !busy) {
      nastyFlag = true;
      handleSubmit();
    }
  }, [isOpen]);

  return isOpen ? (
    <div className="modal-backdrop" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {finished && (
        <div className="modal-content" style={{ background: themes.color2, padding: "20px", borderRadius: "5px" }}>
          <p style={{ color: themes.txtColor }}>Thank you for your enquiry, we will be in touch with you soon</p>
          <button onClick={onClose} style={{ marginTop: "20px", background: themes.color1, color: themes.txtColor, padding: "10px 20px", borderRadius: "5px", border: "none", cursor: "pointer" }}>
            Close
          </button>
        </div>
      )}
      {!finished && !user && (
        <div className="modal-content" style={{ background: themes.color2, padding: "20px", borderRadius: "5px" }}>
          <p style={{ color: themes.txtColor }}>For more details Contact Us by submitting this form</p>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={busy}>
              {!userId && (
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => updateField(event, nameSet)}
                  placeholder="Name *"
                  required
                  style={{
                    margin: "10px 0",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                />
              )}
              {!userId && (
                <input
                  type="tel"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => updateField(event, phoneNumberSet)}
                  placeholder="Phone No. (11 Digits) *"
                  pattern="[0]{1}[0-9]{10}"
                  title="11 Digits eg 07851234567"
                  required
                  style={{
                    margin: "10px 0",
                    padding: "10px",
                    borderRadius: "5px",
                    marginLeft: "10px", // Adding left margin
                  }}
                />
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(event) => updateField(event, emailSet)}
                  placeholder="Email *"
                  required
                  style={{
                    margin: "10px 10px 10px 0", // Add margin to the right to separate from the submit button
                    padding: "10px",
                    borderRadius: "5px",
                    flex: "1", // Take up remaining space
                  }}
                />
                <button
                  type="submit"
                  style={{
                    background: themes.color1,
                    color: themes.txtColor,
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}>
                  Submit
                </button>
              </div>
              <button onClick={onClose} style={{ marginTop: "20px", background: themes.color1, color: themes.txtColor, padding: "10px 20px", borderRadius: "5px", border: "none", cursor: "pointer" }}>
                Close
              </button>
            </fieldset>
          </form>
        </div>
      )}
    </div>
  ) : null;
}
