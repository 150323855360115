import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropertyCard from "../components/property/PropertyCard";
import { useInView } from "react-intersection-observer";
import "../App.css"; // Importing the App.css file
import { Filter, getProperties, numberFormat, Property } from "../utils/properties";
import MultiRangeSlider from "multi-range-slider-react";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent } from "react"; // Import ChangeEvent type from 'react'
import FeaturedCardsCarousel from "./components/FeaturedCardsCarousel";
import FeatureEnquiryModal from "./components/FeatureEnquiryModal";

// Utility function to ensure all elements are numbers
const ensureNumber = (value: any): value is number => {
  return typeof value === "number";
};

let terribleLoadingFlag = false;

function PropertyListing() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [filterProperties, setFilterProperties] = useState<Filter>();
  const [loading, setLoading] = useState(true);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(1000000000);
  const [minBedroom, set_minBedroom] = useState(0);
  const [maxBedroom, set_maxBedroom] = useState(500);
  const [propertyType, setPropertyType] = useState("");
  const [region, setRegion] = useState("");
  const [town, setTown] = useState("");
  const [strategy, setStrategy] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortDate, setSortDate] = useState("Latest"); // Default to sorting by latest
  const [sortPrice, setSortPrice] = useState("By Price");
  const [sortYield, setSortYield] = useState("By Yield");
  const [sortDiscount, setSortDiscount] = useState("By Discount");
  const [isReserved, setIsReserved] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(60);
  const [minYield, set_minYield] = useState(0);
  const [maxYield, set_maxYield] = useState(1000);
  const [dynamicMin, setDynamicMin] = useState<number>(0);
  const [dynamicMax, setDynamicMax] = useState<number>(0);
  const [towns, setTowns] = useState<string[]>([]);
  const [selectedProductId, selectedProductIdSet] = useState<string>("");
  const [filter, setFilter] = useState({});
  const [loadFilter, setLoadFilter] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  // Dropdown handlers that update both the slider and dropdown values
  const handleMinPriceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMin = Number(event.target.value);
    setDynamicMin(newMin);
    set_minValue(newMin); // Update slider to reflect dropdown change
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMax = Number(event.target.value);
    setDynamicMax(newMax);
    set_maxValue(newMax); // Update slider to reflect dropdown change
  };

  const resetYieldSlider = () => {
    if (!filterProperties) {
      console.log("Filter properties not yet loaded");
      return;
    }

    const defaultMinYield = filterProperties.minGrossYield ?? 0;
    const defaultMaxYield = filterProperties.maxGrossYield ?? 1000;
    set_minYield(defaultMinYield);
    set_maxYield(defaultMaxYield);
  };

  const handleYieldInput = (e: any) => {
    set_minYield(e.minValue);
    set_maxYield(e.maxValue);
  };


  useEffect(() => {
    if (filterProperties) {
      setDynamicMin(filterProperties.minPrice ?? 0);
      setDynamicMax(filterProperties.maxPrice ?? 0);
    }
  }, [filterProperties]);

  const handleBedInput = (e: any) => {
    set_minBedroom(e.minValue);
    set_maxBedroom(e.maxValue);
  };

  const resetBedroomsSlider = () => {
    const defaultMinBedrooms = filterProperties?.minBedroom ?? 0;
    const defaultMaxBedrooms = filterProperties?.maxBedroom ?? 0;
    set_minBedroom(defaultMinBedrooms);
    set_maxBedroom(defaultMaxBedrooms);
  };

  // Update the town dropdown when the selected region changes
  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion); // Update the selected region state
    // Call filterAction to update the properties based on the selected region
  };
  const filterAction = async (isReservedOverride = isReserved, startPage = 0) => {
    setLoadFilter(true);
    const filter = {
      ...(region && { region }),
      ...(town && { town }),
      ...(propertyType && { propertyType }),
      ...(strategy && { strategy }),
      ...(minValue !== 0 && minValue !== maxValue && { minPrice: minValue }),
      ...(maxValue !== 100 && minValue !== maxValue && { maxPrice: maxValue }),
      ...(minYield !== 0 && minYield !== maxYield && { minGrossYield: minYield }),
      ...(maxYield !== 100 && minYield !== maxYield && { maxGrossYield: maxYield }),
      ...(searchKeyword && { searchKeyword }),
      ...(maxBedroom !== 10 && minBedroom !== maxBedroom && { maxBedRoom: maxBedroom }),
      ...(minBedroom !== 0 && maxBedroom !== minBedroom && { minBedRoom: minBedroom - 1 }),
      ...(sortDate !== "By Date" && { sortDate }),
      ...(sortPrice !== "By Price" && { sortPrice }),
      ...(sortYield !== "By Yield" && { sortYield }),
      ...(sortDiscount !== "By Discount" && { sortDiscount }),
      isReserved: isReservedOverride,
    };

    setFilter(filter);

    const targetPage = startPage || page;

    console.log('getProperties line 189', {loading, page, targetPage, region, sortPrice, sortDate, sortYield, sortDiscount})
    const pr = await getProperties({ ...filter, page: targetPage, limit });

    setProperties(pr.results);
    setPage(pr.pagination.next);
    setFilterProperties(pr.filter);
    setLoading(false);
    setTowns(pr.filter.towns);
    setLoadFilter(false);
    terribleLoadingFlag = false;
  };

  useEffect(() => {
    if (!terribleLoadingFlag) {
      terribleLoadingFlag = true;
      filterAction(undefined, 1);
    }
  }, [region, sortDate, sortPrice, sortYield, sortDiscount]);

  const viewMore = async () => {
    if (properties && properties.length > 0) {
      setLoadingMore(true);
      if (!loadingMore) {
        console.log('getProperties line 205', {loading, page, region, sortPrice, sortDate, sortYield, sortDiscount})
        const pr = await getProperties({ ...filter, page, limit });
        
        if (pr.pagination.next !== page) {
          setProperties((prev) => [...prev!, ...pr.results]);
        }
        setPage(pr.pagination.next);

        setLoadingMore(false);
      }
    }
  };

  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const sortPriceAction = (data: string) => {
    setSortPrice(data);
    setSortDate("By Date");
    setSortYield("By Yield");
    setSortDiscount("By Discount");
  };

  const sortDateAction = (data: string) => {
    setSortDate(data);
    setSortPrice("By Price");
    setSortYield("By Yield");
    setSortDiscount("By Discount");
  };

  const sortYieldAction = (data: string) => {
    setSortYield(data);
    setSortDate("By Date");
    setSortPrice("By Price");
    setSortDiscount("By Discount");
  };

  const sortDiscountAction = (data: string) => {
    setSortDiscount(data);
    setSortDate("By Date");
    setSortYield("By Yield");
    setSortPrice("By Price");
  };

  const [viewMoreButtonRef, inView] = useInView({});

  useEffect(() => {
    viewMore();
  }, [inView]);

  interface PropsLoading {
    color: string;
  }
  const LoadingDiv = ({ color }: PropsLoading) => {
    return (
      <div className="h-10 w-10">
        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
          <path fill={color} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
          </path>
        </svg>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="PropertiesListing pt-20">
      <Helmet>
        <title>Property Listing</title>
      </Helmet>
      {/* <<=================includes filter=================>> */}
      {!loading && (
        <div className="py-5 pt-10">
          <div className="border max-w-7xl m-auto p-5 rounded-xl shadow-md">
            <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-x-2 gap-1 max-w-7xl m-auto justify-center items-center lg:px-0 px-5">
              {/* Region Select */}
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}>
                  Region
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}
                  value={region} // Use value instead of defaultValue
                  onChange={handleRegionChange} // Call handleRegionChange when region changes
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.regions.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}>
                  Town
                </label>
                <select
                  id="hs-select-label-town"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor: getThemes && getThemes.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                    backgroundColor: region ? "white" : "#e0e0e0",
                    color: region ? "inherit" : "#a0a0a0",
                    cursor: region ? "pointer" : "not-allowed",
                  }}
                  defaultValue={town}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                  disabled={!region}>
                  <option value="">Select</option>
                  {properties &&
                    towns.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}>
                  Strategy
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}
                  defaultValue={strategy}
                  onChange={(e) => {
                    setStrategy(e.target.value);
                  }}>
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.strategy.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}>
                  Property Type
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}
                  defaultValue={propertyType}
                  onChange={(e) => {
                    setPropertyType(e.target.value);
                  }}>
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.propertyType.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>

              <div className="px-2 mt-8">
                <div className="flex rounded-md shadow-sm gap-2">
                  <input
                    type="text"
                    id="hs-trailing-button-add-on-with-icon"
                    name="hs-trailing-button-add-on-with-icon"
                    className="py-3 px-4 block w-full border-primarycolor border-2 rounded-full text-sm"
                    style={{
                      borderColor: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                    }}
                    placeholder="Street name or Postcode (eg CH or CH1)"
                    onInput={(e) => setSearchKeyword(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 grid lg:grid-cols-3 grid-cols-1 lg:gap-x-10 gap-2 max-w-7xl m-auto justify-center items-center lg:px-0 px-5 " id="hs-show-hide-collapse-heading" aria-labelledby="hs-show-hide-collapse">
              <div className="px-2">
                <label
                  htmlFor="customRange1"
                  className="form-label block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                  }}>
                  Price
                </label>
                <div className="mt-2 flex justify-center items-center">
                  <button
                    type="button"
                    className="inline-flex flex-shrink-0 text-white justify-center items-center px-4 py-2 rounded font-semibold text-sm"
                    style={{
                      backgroundColor: getThemes?.color1,
                      color: getThemes?.txtColor,
                      height: "30px",
                      width: "100px",
                      padding: "5px 10px",
                      fontSize: "12px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      border: "none",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color2)}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color1)}
                    onClick={() => {
                      set_minValue(dynamicMin);
                      set_maxValue(dynamicMax);
                    }}>
                    Reset Price
                  </button>
                </div>
                <MultiRangeSlider
                  key={`${dynamicMin}-${dynamicMax}`} // Force re-render when these values change
                  min={filterProperties?.minPrice as unknown as string}
                  max={filterProperties?.maxPrice as unknown as string}
                  step={10000}
                  minValue={minValue} // Use state values directly
                  maxValue={maxValue}
                  onInput={(e) => {
                    set_minValue(e.minValue);
                    set_maxValue(e.maxValue);
                  }}
                  ruler={false}
                  label={false}
                  barInnerColor={getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1}
                  thumbLeftColor={getThemes?.color2}
                  thumbRightColor={getThemes?.color2}
                  className=""
                />
                <div className="filter-container">
                  <select onChange={handleMinPriceChange} value={dynamicMin}>
                    {[filterProperties?.minPrice, 100000, 250000, 500000, 1000000, filterProperties?.maxPrice].filter(ensureNumber).map((value) => (
                      <option key={value} value={value}>{`£${numberFormat(value)}`}</option>
                    ))}
                  </select>

                  <select onChange={handleMaxPriceChange} value={dynamicMax}>
                    {[100000, 250000, 500000, 1000000, filterProperties?.maxPrice].filter(ensureNumber).map((value) => (
                      <option key={value} value={value}>{`£${numberFormat(value)}`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="px-2">
                <div className="relative pt-1">
                  <label
                    htmlFor="customRange1"
                    className="form-label block text-base font-normal mb-2 text-primarycolor"
                    style={{
                      color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                    }}>
                    Bedrooms
                  </label>
                  <div className="mt-2 flex justify-center items-center">
                    <button
                      type="button"
                      className="inline-flex flex-shrink-0 text-white justify-center items-center px-4 py-2 rounded font-semibold text-sm"
                      style={{
                        backgroundColor: getThemes?.color1,
                        color: getThemes?.txtColor,
                        height: "30px",
                        width: "100px",
                        padding: "5px 10px",
                        fontSize: "12px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        border: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color2)}
                      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color1)}
                      onClick={resetBedroomsSlider}>
                      Reset Beds
                    </button>
                  </div>
                  <MultiRangeSlider
                    min={filterProperties?.minBedroom as unknown as string}
                    max={filterProperties?.maxBedroom as unknown as string}
                    step={1}
                    minValue={minBedroom}
                    maxValue={maxBedroom}
                    onInput={handleBedInput}
                    ruler={false}
                    label={false}
                    barInnerColor={getThemes?.color1}
                    thumbLeftColor={getThemes?.color2}
                    thumbRightColor={getThemes?.color2}
                    className="ml-2"
                  />
                  <div className="flex justify-items-center justify-center gap-x-5">
                    <span className="">{filterProperties?.minBedroom as unknown as string}</span>
                    <span>-</span>
                    <span className="">{filterProperties?.maxBedroom as unknown as string}</span>
                  </div>
                </div>
              </div>

              <div className="px-2">
                <div className="relative pt-1">
                  <label
                    htmlFor="customRange1"
                    className="form-label block text-base font-normal mb-2 text-primarycolor"
                    style={{
                      color: getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1,
                    }}>
                    Gross Yield
                  </label>
                  <div className="mt-2 flex justify-center items-center">
                    <button
                      type="button"
                      className="inline-flex flex-shrink-0 text-white justify-center items-center px-4 py-2 rounded font-semibold text-sm"
                      style={{
                        backgroundColor: getThemes?.color1,
                        color: getThemes?.txtColor,
                        height: "30px",
                        width: "100px",
                        padding: "5px 10px",
                        fontSize: "12px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        border: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color2)}
                      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = getThemes?.color1)}
                      onClick={resetYieldSlider}>
                      Reset Yield
                    </button>
                  </div>
                  <MultiRangeSlider
                    min={filterProperties?.minGrossYield as unknown as string}
                    max={filterProperties?.maxGrossYield as unknown as string}
                    step={1}
                    minValue={minYield}
                    maxValue={maxYield}
                    onInput={handleYieldInput}
                    ruler={false}
                    label={false}
                    barInnerColor={getThemes?.color1}
                    thumbLeftColor={getThemes?.color2}
                    thumbRightColor={getThemes?.color2}
                    className="ml-2"
                  />
                  <div className="flex justify-items-center justify-center gap-x-5">
                    <span className="">{filterProperties?.minGrossYield as unknown as string}%</span>
                    <span>-</span>
                    <span className="">{filterProperties?.maxGrossYield as unknown as string}%</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="mt-5 inline-flex flex-shrink-0 text-white-f2f9fa justify-center items-center h-[2.875rem] w-[150px] rounded-full font-semibold bg-primarycolor hover:bg-primarycolor/[.7] text-sm"
              style={{ backgroundColor: getThemes && getThemes.color1, transition: "background-color 0.3s" }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = getThemes?.color2 || "alternateHoverColor"; // Assign hover color from color2
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = getThemes?.color1 || "defaultColor"; // Revert to original color
              }}
              onClick={() => filterAction()}>
              {!loadFilter && (
                <div className="flex flex-row justify-between items-center w-full px-10 text-lg">
                  Filter
                  <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
              )}
              {loadFilter && <LoadingDiv color={"#fff"} />}
            </button>
          </div>
        </div>
      )}

      {!loading && (
        <div className="flex flex-wrap justify-between m-auto max-w-7xl mb-5">
          <div className="flex flex-wrap justify-end  items-center gap-3">
            <div className="flex items-center">
              <input
                onChange={async (e) => {
                  const isChecked = e.target.checked;
                  setIsReserved(isChecked); // Update the state based on checkbox
                  // Invoke filterAction with the new checkbox state
                  await filterAction(isChecked);
                }}
                checked={isReserved}
                id="disabled-checked-checkbox"
                type="checkbox"
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="disabled-checked-checkbox" className="ml-2 text-sm font-medium cursor-pointer text-gray-400 dark:text-gray-500">
                Show Reserved Stock
              </label>
            </div>
          </div>
          <div className="flex flex-wrap justify-end  items-center gap-3">
            <h3 className="text-gray-700 font-medium">Sort By : </h3>
            <div className="hs-dropdown relative inline-flex">
              <button id="hs-dropdown-default" type="button" className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm">
                {sortDate.replace("Date", "").replace("Latest", "Newest")} Date
                <svg className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>

              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50" aria-labelledby="hs-dropdown-default">
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDateAction("Latest");
                  }}>
                  Newest Date
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDateAction("Oldest");
                  }}>
                  Oldest Date
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button id="hs-dropdown-default" type="button" className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm">
                {sortPrice.replace("Price", "")} Price
                <svg className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>

              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50" aria-labelledby="hs-dropdown-default">
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortPriceAction("Lowest");
                  }}>
                  Lowest Price
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortPriceAction("Highest");
                  }}>
                  Highest Price
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button id="hs-dropdown-default" type="button" className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm">
                {sortYield.replace("Yield", "")} Yield
                <svg className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>

              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50" aria-labelledby="hs-dropdown-default">
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortYieldAction("Lowest");
                  }}>
                  Lowest Yield
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortYieldAction("Highest");
                  }}>
                  Highest Yield
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button id="hs-dropdown-default" type="button" className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm">
                {sortDiscount.replace("Discount", "")} Discount
                <svg className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>

              <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50" aria-labelledby="hs-dropdown-default">
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDiscountAction("Lowest");
                  }}>
                  Lowest Discount
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDiscountAction("Highest");
                  }}>
                  Highest Discount
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <<=================includes property listing=================>> */}

      <div className="max-w-7xl m-auto pb-10" style={{ overflowX: "hidden" }}>
        {!loading && (
          <div
            style={{
              background: `${getThemes && getThemes.color2}`,
              marginBottom: "20px", // Add Margin here
              padding: "5px",
            }}
            className="rounded-xl">
            <h3
              className="text-left ml-3 mt-4 text-md inline-block float-left px-5 py-1 text-white-f2f9fa rounded-xl"
              style={{
                background: `${getThemes && getThemes.color1}`,
                color: `${getThemes && getThemes.txtColor}`,
              }}>
              <FontAwesomeIcon icon={faStar} className="mr-1 text-sm" /> Featured Products
            </h3>
            <FeaturedCardsCarousel setIsModalOpen={setIsModalOpen} selectedProductIdSet={selectedProductIdSet} />
            <FeatureEnquiryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} productId={selectedProductId} />
          </div>
        )}
        {!loadFilter && (
          <>
            <div className="grid lg:grid-cols-3 grid-cols-1 w-full m-auto gap-3 lg:px-0 px-3">
              {properties?.map((ele, index) => (
                <PropertyCard
                  key={index}
                  discount={ele.discountToMarket as unknown as string}
                  discountLabel={ele.discountLabel}
                  id={ele._id}
                  available={ele.reserved}
                  image={!!ele.images ? ele.images[0].url : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"}
                  streetName={ele.propertyOf}
                  price={ele.price as unknown as string}
                  bedrooms={ele.bedrooms as unknown as string}
                  value={ele.estimatedValue as unknown as string}
                  rent={ele.averageRent as unknown as string}
                  netCashFlow={ele.netCashflow as unknown as string}
                  grossYield={ele.rentGrossYield as unknown as string}
                  strategy={ele.strategy as unknown as string}
                />
              ))}
            </div>
            {!loading && properties?.length == 0 && (
              <div className="flex w-full justify-center items-center mt-10">
                <h1 className="text-center">No Properties Found</h1>
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="h-screen flex top-[50%] bottom-0 m-auto justify-center items-center">
            <LoadingDiv color={getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />
          </div>
        )}
        {loadFilter && (
          <div className="flex w-full justify-center items-center  mt-10">
            <LoadingDiv color={getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />
          </div>
        )}

        {!loading && !loadFilter && (
          <div ref={viewMoreButtonRef}>
            <div className="w-full justify-center items-center">
              <button className="">{loadingMore && <LoadingDiv color={getThemes && getThemes.color1 == "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyListing;
