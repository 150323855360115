import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  faPoundSign,
  faBed,
  faLocationArrow,
  faChartLine,
  faHandshake,
  faCertificate,
  faMoneyBill1Wave,
  faChartBar,
  faMoneyBill,
  faMoneyCheck,
  faStamp,
  faHome,
  faCheckDouble,
  faCheck,
  faExpandArrowsAlt,
  faBullseye,
  faWalking,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkout,
  getProperty,
  numberFormat,
  orderProperty,
  postMakeAnOffer,
  PropertyExtraResult,
} from "../utils/properties";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import ImageSlider from "../components/property/Sidler";

function Property() {
  const [property, setProperty] = useState<PropertyExtraResult>();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const router = useParams();
  const path = useLocation();
  const getToken = useTokenStore((state) => state.token);
  const navigate = useNavigate();

  const [interest, setInterest] = useState(5);
  const [loan, setLoan] = useState(75);

  const [deposite, setDeposite] = useState(0);
  const [brokage, setBrokage] = useState(0);
  const [stampDuty, setStampDuty] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [management, setManagement] = useState(0);
  const [opex, setOpex] = useState(0);
  const [mortgage, setMortgage] = useState(0);
  const [netCashflow, setNetCashflow] = useState(0);
  const [netAnnualCashflow, setNetAnnualCashflow] = useState(0);
  const [grossYield, setGrossYield] = useState("");
  const [discount, setDiscount] = useState("");

  interface MenuBarProps {
    shadowed: boolean;
  }
  
  const MenuBar: React.FC<MenuBarProps> = ({ shadowed }) => {
    return (
      <div className={`your-existing-menu-classes ${shadowed ? 'shadow-lg' : ''}`}>
        {/* Your menu bar content */}
      </div>
    );
  };

  function formatNumber(value: number | undefined): string {
    if (value === undefined) return ""; // Handle undefined case
    return value.toLocaleString(); // This uses the browser's locale to add commas appropriately
  }
  
  const [formattedPrice, setFormattedPrice] = useState("");

    useEffect(() => {
      if (property?.results.price !== undefined) {
        setFormattedPrice(formatNumber(property.results.price));
      }
    }, [property?.results.price]); // Update formatted price when the property changes

  

    const handlePurchasePriceChange = (formattedValue: string, propData: PropertyExtraResult) => {
      // Remove commas before parsing
      const priceValue = parseInt(formattedValue.replace(/,/g, ''), 10);
      if (!isNaN(priceValue)) {
        const updatedProperty = { ...propData, results: { ...propData.results, price: priceValue }};
        setProperty(updatedProperty);
        recalculateFinancials(updatedProperty);
      } else {
        console.error("Invalid price input");
      }
      setFormattedPrice(formattedValue); // Keep input display in sync with user input
    };
    
  
  const recalculateFinancials = (updatedProperty: PropertyExtraResult) => {
    calculateDesposite(loan / 100, updatedProperty);
    calculateBrokage(loan / 100, updatedProperty);
    calculateStampDuty(updatedProperty);
    calulateTotalInvestment(updatedProperty, loan / 100);
    calculaterMortgae(updatedProperty, interest);
    calculaterNetCashflow(updatedProperty, mortgage);
  };

  useEffect(() => {
    if (property) {
      calculaterMortgae(property, interest);
      calculaterNetCashflow(property, mortgage);
      calculateStampDuty(property);
      calculateDesposite(loan / 100, property);
      calculateBrokage(loan / 100, property);
      calulateTotalInvestment(property, loan / 100);
    }
  }, [property, interest, loan, mortgage]);
  

  const calculateDesposite = (
    loanPercentage: number,
    propData: PropertyExtraResult
  ) => {
    setDeposite(
      (propData.results.price -
        propData.results.price * loanPercentage) as unknown as number
    );
  };

  const calculateBrokage = (
    loanPercentage: number,
    propData: PropertyExtraResult
  ) => {
    setBrokage(
      (propData.results.price * loanPercentage * 0.01) as unknown as number
    );
  };

  const calculateStampDuty = (propData: PropertyExtraResult) => {
    let price = propData.results.price;
    let stampDuty = 0;
  
    // Calculate 3% for the first £250,000
    const firstBracket = price > 250000 ? 250000 : price;
    stampDuty += firstBracket * 0.03;
  
    // Calculate 8% for £250,001 to £925,000
    if (price > 250000) {
      const secondBracket = price > 925000 ? 675000 : price - 250000;
      stampDuty += secondBracket * 0.08;
    }
  
    // Calculate 13% for £925,001 to £1,500,000
    if (price > 925000) {
      const thirdBracket = price > 1500000 ? 575000 : price - 925000;
      stampDuty += thirdBracket * 0.13;
    }
  
    // Calculate 15% for anything over £1,500,000
    if (price > 1500000) {
      const excess = price - 1500000;
      stampDuty += excess * 0.15;
    }
  
    setStampDuty(stampDuty);
  };

  const calulateTotalInvestment = (
    propData: PropertyExtraResult,
    loanPercentage: number
  ) => {
    let invest =
      ((((((propData.results.price -
        propData.results.price * loanPercentage) as unknown as number) +
        propData.results.price * loanPercentage * 0.01) as unknown as number) +
        propData.results.price * 0.03) as unknown as number) +
      parseInt(propData.results.legal as unknown as string) +
      parseInt(propData.results.setupFeeValuation as unknown as string);
    setTotalInvestment(invest);
  };

  const calculaterMortgae = (
    propData: PropertyExtraResult,
    interest: number
  ) => {
    let mortgage =
      (((parseInt(propData.results.price as unknown as string) * loan) / 100) *
        (interest / 100)) /
      12;
    setMortgage(mortgage);
    calculaterNetCashflow(propData, mortgage);
  };

  const calculaterNetCashflow = (
    propData: PropertyExtraResult,
    mortgage: number
  ) => {
    let netcashflow =
      parseInt(propData.results.averageRent as unknown as string) - mortgage;

    setNetCashflow(netcashflow);
    setNetAnnualCashflow(netcashflow * 12);

    setGrossYield(
      (
        ((parseInt(propData.results.averageRent as unknown as string) * 12) /
          parseInt(propData.results.price as unknown as string)) *
        100
      ).toFixed(1)
    );

    setDiscount(
      (
        (parseInt(propData.results.estimatedValue as unknown as string) -
          parseInt(propData.results.price as unknown as string)) /
        parseInt(propData.results.price as unknown as string)
      ).toFixed(3)
    );
  };

  const handleInterest = (e: any, propData: PropertyExtraResult) => {
    setInterest(e);
    calculaterMortgae(propData, e);
  };
  const handleLoan = (e: any, propData: PropertyExtraResult) => {
    setLoan(parseInt(e));
    const loanPercentage = e / 100;
    calculateDesposite(loanPercentage, propData);
    calculateBrokage(loanPercentage, propData);
    calculateStampDuty(propData);
    calulateTotalInvestment(propData, loanPercentage);

    let ope = 0;
    if (propData.results.strategy == "HMO") {
      ope = parseInt(propData.results.averageRent as unknown as string) * 0.35;
    } else if (propData.results.strategy == "Housing Association") {
      ope = 0;
    } else {
      ope = parseInt(propData.results.averageRent as unknown as string) * 0.1;
    }
    calculaterMortgae(propData, interest);
  };

  const reservePropertySuccess = async () => {
    const response = await orderProperty({
      connectedTo: router.id as string,
      paymentStatus: "success",
      token: getToken!,
    });

    if (response.status === 201) {
      setSuccessPopup(true);
      setMessage(response.message);
    }
    if (response.status !== 201) {
      setErrorPopup(true);
      setMessage(response.message);
    }
  };

  const reservePropertyFailure = async () => {
    const response = await orderProperty({
      connectedTo: router.id as string,
      paymentStatus: "failure",
      token: getToken!,
    });
    if (response.status !== 201) {
      setErrorPopup(true);
      setMessage(response.message);
    }
  };

  useEffect(() => {
    // Only fetch property data if it hasn't been fetched already
    if (!property && getToken) {
      const fetchProperty = async () => {
        const pr = await getProperty(router.id as string);
        if (pr) {
          setProperty(pr);
          setLoading(false);
        }
      };
      fetchProperty();
    }
  }, [router.id, getToken]); // Dependencies ensure this runs only on component mount or when the route or token changes

  // Button Reset 

  const [initialValues, setInitialValues] = useState({
    price: 0,
    interest: 5,
    loan: 75
  });

  useEffect(() => {
    const fetchProperty = async () => {
      const pr = await getProperty(router.id as string);
      if (pr) {
        setProperty(pr);
        setLoading(false);
        setInitialValues({
          price: pr.results.price, // Assuming price is directly under results
          interest: 5, // Default interest rate
          loan: 75 // Default loan to value
        });
      }
    };
    fetchProperty();
  }, [router.id, getToken]); // Only run on component mount or when the route or token changes

  const handleReset = () => {
    if (property && initialValues.price !== 0) {
      const resetProperty = { ...property, results: { ...property.results, price: initialValues.price }};
      setProperty(resetProperty);
      setInterest(initialValues.interest);
      setLoan(initialValues.loan);
      
      // Recalculate directly without changing recalculateFinancials
      calculateDesposite(initialValues.loan / 100, resetProperty);
      calculateBrokage(initialValues.loan / 100, resetProperty);
      calculateStampDuty(resetProperty);
      calulateTotalInvestment(resetProperty, initialValues.loan / 100);
      calculaterMortgae(resetProperty, initialValues.interest);
      calculaterNetCashflow(resetProperty, mortgage);
    }
  };
  
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  useEffect(() => {
    if (path.pathname.split("/").pop() === "success") {
      reservePropertySuccess();
      setTimeout(() => {
        navigate(`/listing/${router.id}`);
        setSuccessPopup(false);
        setErrorPopup(false);
      }, 5000);
    }
    if (path.pathname.split("/").pop() === "failure") {
      reservePropertyFailure();
      setTimeout(() => {
        navigate(`/listing/${router.id}`);
        setErrorPopup(false);
        setSuccessPopup(false);
      }, 5000);
    }

    // submitCheckout();

    const fetchProperty = async () => {
      const pr = await getProperty(router.id as string);
      handleLoan(loan.toString(), pr!);
    };
    fetchProperty();
  }, []);

  const [paymentLink, setPaymentLink] = useState("");

  const submitCheckout = async () => {
    setSubmitLoading(true);
    let response = await checkout({
      id: router.id as string,
      url: `trustbricks.co.uk/properties/${router.id}`,
      token: getToken!,
    });

    setPaymentLink(response.session.url);
    setSubmitLoading(false);
  };

  const [offer, setOffer] = useState<string>("");

  const submitOffer = async () => {
    setSubmitLoading(true);
    let response = await postMakeAnOffer({
      connectedTo: router.id as string,
      offer: offer,
      propertyLink: window.location.href,
      token: getToken!,
    });
    setSubmitLoading(false);
    setSuccessPopup(true);
    setMessage(response.message);
    setOffer("");
    closeBtnRef.current?.click();
  };

  const closeBtnRef = useRef<HTMLButtonElement>(null);


  //Clean string
  function cleanString(input: string): string {
  const unwantedSymbols = ['¬', '∑']; // Add more symbols as needed
  let output = input;
  unwantedSymbols.forEach(symbol => {
      output = output.replace(new RegExp(symbol, 'g'), ''); // Replace each symbol with an empty string
  });
  return output;
}

  return (
    <>

 {loading && (
        <div className="h-screen flex top-0 bottom-0 m-auto justify-center ">
          <div
            className="align-middle animate-spin inline-block w-8 h-8 border-[2px] border-primarycolor border-t-transparent text-secondarycolor rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!loading && (
        <div className="Properties">
          <Helmet>
            <title>Properties</title>
          </Helmet>
          <MenuBar shadowed={true} />
          {/* <==============above banner=================> */}
          
          <div className="w-full h-full relative overflow-hidden" style={{ background: `linear-gradient(to bottom left, ${getThemes && getThemes.color1} 50%, ${getThemes && getThemes.color2} 50%)` }}>
            <div className="absolute inset-0" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%)', background: getThemes && getThemes.color1}}></div>
            <div className="absolute inset-0" style={{ clipPath: 'polygon(0 0, 100% 100%, 0 100%)', background: getThemes && getThemes.color2}}></div>
            <div className="grid lg:grid-cols-2 grid-cols-1 items-start max-w-7xl m-auto lg:py-14 py-5 lg:px-0 px-4 lg:gap-24 sm:gap-15" style={{ position: 'relative', marginTop: '125px'}}>
              <div className="text-white-f2f9fa text-left font-roboto gap-5 grid">
                <h4 className="lg:text-4xl text-2xl font-semibold" style={{ color: getThemes.txtColor }}>
                  {property?.results.bedrooms} Bed Property
                </h4>
                <h4 className="lg:text-lg sm:text-base align-middle flex gap-x-1 items-center responsive-heading" style={{ color: getThemes.txtColor, maxWidth: "100%" }}>
                  <FontAwesomeIcon icon={faLocationArrow} />
                  <span style={{ wordBreak: "break-all" }}>
                    {property?.results.fullAddress}
                  </span>
                </h4>

                  <ImageSlider 
                    images={property!.results.images!}
                  />
              <div style={{marginTop: '20px'}}>
                <small style={{ color: getThemes.txtColor }}>Ref : {property?.results._id}</small>
              </div>
              <div>
                  <div
                    id="hs-slide-down-animation-modal"
                    className={`hs-overlay ${
                      successPopup || errorPopup ? "open" : "hidden"
                    } w-full h-full fixed top-0 left-0 z-[99999] overflow-x-visible overflow-y-auto`}
                  >
                    <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                      {!successPopup && !errorPopup && (
                        <div className="flex flex-col bg-white-f2f9fa border shadow-sm rounded-xl" style={{ background: `linear-gradient(to bottom left, ${getThemes && getThemes.color1} 50%, ${getThemes && getThemes.color2} 50%)` }}>
                          <div className="flex justify-between items-center py-3 px-4 border-b">
                            <h3 className="font-bold text-gray-800" style={{ color: getThemes.txtColor }}>
                              Payment Confirmation
                            </h3>
                            <button
                              type="button"
                              className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                              data-hs-overlay="#hs-slide-down-animation-modal"
                            >
                              <span className="sr-only">Close</span>
                              <svg
                                className="w-3.5 h-3.5"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="p-4 overflow-y-auto">
                            <p className="mt-1 text-gray-800 font-medium" style={{ color: getThemes.txtColor }}>
                              You wish to reserve this property
                            </p>
                          </div>
                          <div className="px-4 overflow-y-auto">
                            <div className="flex flex-wrap border p-1 mb-4">
                              <div className="w-1/4">
                                <img
                                  className=""
                                  src={
                                    property?.results.images
                                      ? property?.results.images[0]?.url
                                      : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="w-3/4 px-3">
                                <h3 className="text-xl text-primarycolor font-medium" style={{ color: getThemes.txtColor }}>
                                  {property?.results.fullAddress}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="px-5 pb-3">
                            <p className="text-black-1c2023 text-sm " style={{ color: getThemes.txtColor }}>
                              <b>Sourcing Fee:</b> Sourcing Fee: The standard
                              sourcing fee for each deal is 2.4% of the purchase
                              price (incl UK VAT) with a minimum of £3,600 (incl
                              UK VAT). Reserving this deal simply requires a
                              fully refundable upfront reservation fee, with the
                              balance paid later in stages if you proceed with
                              the purchase.
                            </p>
                          </div>
                          <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
                            <a
                              href={paymentLink}
                              type="button"
                              className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-primarycolor text-white-f2f9fa shadow-sm align-middle hover:bg-btnscolor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                              style={{
                                background: getThemes && getThemes.color1,
                              }}
                              data-hs-overlay="#hs-static-backdrop-modal"
                              // onClick={() => submit()}
                            >
                              Pay Now (£{property?.results.reservationPrice}){" "}
                              {submitLoading && (
                                <span
                                  className="animate-spin border-btnscolor  inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                                  role="status"
                                  aria-label="loading"
                                ></span>
                              )}
                            </a>
                            <button
                              type="button"
                              className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white-f2f9fa text-gray-700 shadow-sm align-middle hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                              data-hs-overlay="#hs-slide-down-animation-modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                      {(successPopup || errorPopup) && (
                        <div>
                          <div className="">
                            <div className="flex flex-col bg-white-f2f9fa border shadow-sm rounded-xl">
                              <div className="p-4 m-auto">
                                <img
                                  src={`https://irimiaionut.github.io/Animated-Icons-SVG/resources/essential/${
                                    successPopup ? "success.svg" : "deny.svg"
                                  }`}
                                  className="m-auto w-36"
                                  alt=""
                                />
                                <h3 className="text-2xl text-primarycolor font-medium text-center">
                                  {property?.results.fullAddress}
                                </h3>
                                <p className="mt-1 text-primarycolor font-medium text-lg text-center">
                                  {message}
                                </p>
                              </div>

                              <div className="m-auto flex justify-end items-center gap-x-2 py-3 px-4">
                                <button
                                  type="button"
                                  className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white-f2f9fa text-gray-700 shadow-sm align-middle hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                                  onClick={() => {
                                    navigate(`/listing/${router.id}`);
                                    setSuccessPopup(false);
                                    setErrorPopup(false);
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    id="hs-slide-down-animation-modal1"
                    className={`hs-overlay ${
                      offerPopup ? "open" : "hidden"
                    } w-full h-full fixed top-0 left-0 z-[99999] overflow-x-visible overflow-y-auto`}
                  >
                    <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                      <div className=" flex flex-col bg-white-f2f9fa border shadow-sm rounded-xl" style={{ background: `linear-gradient(to bottom left, ${getThemes && getThemes.color1} 50%, ${getThemes && getThemes.color2} 50%)` }}>
                        <div className="flex justify-between items-center py-3 px-4 border-b">
                          <h3 className="font-bold text-gray-800" style={{ color: getThemes.txtColor}}>
                            Make an offer, what have you got to lose?
                          </h3>
                          <button
                            type="button"
                            className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                            data-hs-overlay="#hs-slide-down-animation-modal1"
                            onClick={() => setOfferPopup(false)}
                            ref={closeBtnRef}
                          >
                            <span className="sr-only">Close</span>
                            <svg
                              className="w-3.5 h-3.5"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="p-2 overflow-y-auto">
                          {/* <p className="mt-1 text-gray-800 font-medium">
                            You wish to make offer for this property
                          </p> */}
                        </div>
                        <div className="px-4 overflow-y-auto">
                          <div className="flex flex-wrap border p-1 mb-4">
                            <div className="w-1/4">
                              <img
                                className=""
                                src={
                                  property?.results.images
                                    ? property?.results.images[0]?.url
                                    : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                                }
                                alt=""
                              />
                            </div>
                            <div className="w-3/4 px-3">
                              <h3 className="text-xl text-primarycolor font-medium" style={{ color: getThemes.txtColor }}>
                                {property?.results.fullAddress}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="px-5 mb-3">
                          <h3 style={{ color: getThemes.txtColor }}>Your Offer</h3>
                          <textarea
                            name=""
                            rows={5}
                            placeholder="Please enter your offer here"
                            className="mt-3 p-3 text-black-1c2023 border border-gray-500 rounded-md w-full resize-none"
                            value={offer}
                            onChange={(e) => setOffer(e.target.value)}
                          ></textarea>
                        </div>

                        <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
                          {!submitLoading && (
                            <button
                              type="button"
                              className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-primarycolor text-white-f2f9fa shadow-sm align-middle hover:bg-btnscolor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                              style={{
                                background: getThemes && getThemes.color1,
                              }}
                              onClick={() => submitOffer()}
                            >
                              Submit
                            </button>
                          )}
                          {submitLoading && (
                            <button
                              type="button"
                              className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-primarycolor text-white-f2f9fa shadow-sm align-middle hover:bg-btnscolor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                              style={{
                                background: getThemes && getThemes.color1,
                              }}
                            >
                              Submitting...
                            </button>
                          )}
                          <button
                            type="button"
                            className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white-f2f9fa text-gray-700 shadow-sm align-middle hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                            data-hs-overlay="#hs-slide-down-animation-modal1"
                            onClick={() => setOfferPopup(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <===========Success payment=============> */}

                <div className="flex flex-col">
                  <button
                    className="relative w-full sm:w-3/5 max-w-[215px] sm:max-w-[400px] py-3 rounded-full text-white-f2f9fa hover:bg-white-f2f9fa text-base font-medium transition duration-500 hover:scale-110 mt-5 sm:mt-10 noprint text-center"
                    style={{ background: getThemes && getThemes.color1 }}
                    data-hs-overlay="#hs-slide-down-animation-modal1"
                  >
                    Make An Offer
                    <span className="hover-line"></span>
                  </button>
                  <button
                    className="relative w-full sm:w-3/5 max-w-[215px] sm:max-w-[400px] py-3 bg-btnscolor rounded-full text-white-f2f9fa hover:bg-white-f2f9fa text-base font-medium transition duration-500 hover:scale-110 mt-5 sm:mt-10 noprint text-center"
                    data-hs-overlay="#hs-slide-down-animation-modal"
                    style={{ background: getThemes && getThemes.color1 }}
                    onClick={() => submitCheckout()}
                  >
                    Reserve
                    <span className="hover-line"></span>
                  </button>
                  <button
                    onClick={() => window.print()}
                    className="relative w-full sm:w-3/5 max-w-[215px] sm:max-w-[400px] py-3 bg-btnscolor rounded-full text-white-f2f9fa hover:bg-white-f2f9fa text-base font-medium transition duration-500 hover:scale-110 mt-5 sm:mt-10 noprint text-center"
                    style={{ background: getThemes && getThemes.color1 }}
                  >
                    Download Property PDF
                    <span className="hover-line"></span>
                  </button>
                </div>
            </div>
              <div className="text-white-f2f9fa text-left rounded-xl">
                <h4 className="lg:text-3xl sm:text-2xl font-semibold pt-4 mb-2" style={{ color: getThemes.txtColor }}>
                  Property Description
                </h4>

                <pre className="font-poppins text-sm max-w-full textWrap" style={{ color: getThemes.txtColor }}>
                  {property?.results.propertyDescription}
                </pre>
                {property?.results.benefits &&
                  property?.results.benefits?.length > 0 && (
                    <div className="mt-4">
                      {property?.results.benefits?.map((ele, index) => (
                        <h4
                          className="align-middle gap-x-2 items-center flex "
                          style={{ color: getThemes.txtColor }}
                          key={index}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {ele}
                        </h4>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>

          {/* <==============below fold with side=================> */}
          <div className="w-full sm:py-3 lg:py-8 xl:py-10">
            <div className="grid lg:grid-cols-3 sm:grid-cols-1 max-w-7xl m-auto lg:py-5 lg:px-0 p-5 gap-5">
              <div className="bg-white border-black-300 border shadow-lg rounded-xl font-roboto lg:my-0 my-2 lg:col-span-2">
                <div className="border-b border-black-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }} >
                  <h3 className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }} >
                    Financial information
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2}}>
                  <div className="w-full flex flex-wrap gap-2" style={{marginTop: '10px', marginBottom: '10px'}}>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor}}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faPoundSign} />
                          <b>Purchase price:</b> £
                          {numberFormat(
                            property?.results.price as unknown as number
                          )}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faPoundSign} />
                          <b>Current Rent:</b> £
                          {numberFormat(
                            property?.results.currentRent as unknown as number
                          )}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faEnvelopeOpen} />
                          <b>Deposit:</b> £{numberFormat(deposite)}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faPoundSign} />
                          <b>Estimated Rent:</b> £
                          {numberFormat(
                            property?.results.averageRent as unknown as number
                          )}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHandshake} />
                          <b>Brokerage (1% of borrowing):</b> £
                          {numberFormat(brokage)}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faMoneyBill1Wave} />
                          <b>Mortgage {property?.results.mortgageLabel}:</b> £
                          {numberFormat(mortgage)}
                        </h4>
                      </div>
                      {/* <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHandsHelping} />
                          <b>Management Fees:</b> £{numberFormat(management)}
                        </h4>
                      </div> */}
                    </div>
                    {/* <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                     
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faCertificate} />
                          <b>Operating Expenses:</b> £{numberFormat(opex)}
                        </h4>
                      </div>
                    </div> */}
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faCertificate} />
                          <b>Valuation & mortgage set-up-fees:</b> £
                          {numberFormat(
                            property?.results
                              .setupFeeValuation as unknown as number
                          )}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faMoneyBill} />
                          <b>Net cashflow:</b> £{numberFormat(netCashflow)}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faStamp} />
                          <b>Stamp duty:</b> £{numberFormat(stampDuty)}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                         <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faMoneyCheck} />
                          <b>Net annual cashflow:</b> £
                          {numberFormat(netAnnualCashflow)}
                        </h4>
                      </div>
                    </div>

                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faChartBar} />
                          <b>Legal Fees:</b> £
                          {numberFormat(
                            property?.results.legal as unknown as number
                          )}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faChartLine} />
                          <b>Gross yield:</b> {grossYield}%
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faStamp} />
                          <b>Total investment:</b> £
                          {numberFormat(totalInvestment)}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                         <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faStamp} />
                          <b>Occupancy:</b> {property?.results.occupancy}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faStamp} />
                          <b>Zoopla Valuation:</b> £
                          {numberFormat(
                            property?.results
                              .estimatedValue as unknown as number
                          )}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                      <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faStamp} />
                          <b>Condition:</b>

                          {property?.results.refurbishmentCost}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-gradient-to-br from-primarycolor to-secondarycolor border shadow-sm rounded-xl font-roboto h-min py-4 lg:col-span-1"
                style={{
                  background: `linear-gradient(to bottom,${
                    getThemes && getThemes!.color1
                  },${getThemes && getThemes.color2})`,
                }}
              >
                <div className="rounded-t-xl px-4 md:px-5 pb-4">
                  <h3 className="text-3xl font-semibold tracking-normal text-white-f2f9fa text-left">
                    Calculate your returns
                  </h3>
                  <p className="tracking-normal text-white-f2f9fa text-left mt-2">
                    Move the sliders below to better understand the financials
                    for this property, based on your personal circumstances.
                  </p>

                  <h4 className="text-lg font-medium tracking-normal text-white-f2f9fa text-center mt-3">Set Your Own Purchase Price</h4>
                    <div className="text-white-f2f9fa flex items-center justify-center">
                    <input
                      type="text"
                      value={formattedPrice}
                      className="slider w-full mt-3 text-black-1c2023 p-2"
                      id="purchasePrice"
                      onInput={(e) => handlePurchasePriceChange(e.currentTarget.value, property!)}
                    />
                      <span className="block mt-3 ml-4">£</span>
                    </div>

                  <h4 className="text-lg font-medium tracking-normal text-white-f2f9fa text-center mt-3">
                    Interest Rate
                  </h4>

                  <div className="text-white-f2f9fa flex items-center justify-center">
                    <input
                      type="text"
                      value={interest}
                      className="slider w-full mt-3 text-black-1c2023 p-2"
                      id="interestRate"
                      onInput={(e) =>
                        handleInterest(e.currentTarget.value, property!)
                      }
                    />
                    <span className="block mt-3 ml-4">%</span>
                  </div>
                  
                  <h4 className="text-lg font-medium tracking-normal text-white-f2f9fa text-center  mt-5">
                    Loan To Value
                  </h4>

                  <div className="text-white-f2f9fa flex items-center justify-center">
                  <span className="block mt-3 mr-4">1</span>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={loan}
                        className="slider w-full mt-3"
                        id="loanToValue"
                        onChange={(e) =>
                            handleLoan(parseInt(e.currentTarget.value), property!)
                        }
                        style={({
                            background: getThemes?.color1, // Set the barInnerColor
                            "--thumb-color": getThemes?.color1, // Set the thumb color
                        } as any)}
                    />
                    <span className="block mt-3 ml-4">100</span>
                </div>
                  <span className="text-white-f2f9fa">{loan}</span>
                </div>
                <button
                  className="relative w-full sm:w-3/5 max-w-[215px] sm:max-w-[400px] py-3 bg-btnscolor rounded-full text-white-f2f9fa hover:bg-white-f2f9fa text-base font-medium transition duration-500 hover:scale-110 noprint text-center"
                  onClick={handleReset}
                  style={{ background: getThemes && getThemes.color1 }}

        >
          Reset Values
        </button>
              </div>
            </div>
          </div>
          {/* <==============below fold without side/bg=================> */}
          <div className="w-full sm:py-2 lg:py-6 xl:py-6">
            <div className="flex max-w-7xl m-auto p-5 lg:p-0">
              <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
                <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }}>
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left " style={{ color: getThemes.txtColor }}
                  >
                    Comparables
                  </h3>
                  <h3
                    className="mt-1 text-lg font-medium tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Previously Sold - Within The Area
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                  <div className="w-full flex flex-wrap gap-2">
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left gap-5">
                    {property?.results.marketComparable?.map((ele, index) => (
        <div
            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
            style={{ color: getThemes.txtColor }}
            key={index + "marketComparable"}
        >
            <h4 className="align-middle flex flex-wrap gap-x-2">
                <FontAwesomeIcon icon={faHome} />
                <b>Address:</b> {ele && <p>{cleanString(ele)}</p>}
            </h4>
        </div>
    ))}
    {property?.results.marketComparable?.length === 0 && (
        <>
            <div
                className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                style={{ color: getThemes.txtColor }}
            >
                <h4 className="align-middle flex flex-wrap gap-x-2">
                    <FontAwesomeIcon icon={faHome} />
                    <b>Address:</b> <p>N/A</p>
                </h4>
                          </div>
                          <div
                            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                            style={{ color: getThemes.txtColor }}
                          >
                            <h4 className="align-middle flex flex-wrap gap-x-2">
                              <FontAwesomeIcon icon={faHome} />
                              <b>Address:</b> <p>N/A</p>
                            </h4>
                          </div>
                          <div
                            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                            style={{ color: getThemes.txtColor }}
                          >
                            <h4 className="align-middle flex flex-wrap gap-x-2">
                              <FontAwesomeIcon icon={faHome} />
                              <b>Address:</b> <p>N/A</p>
                            </h4>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:py-2 lg:py-4 xl:py-4">
            <div className="flex max-w-7xl m-auto p-5 lg:p-0">
              <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
                <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }}>
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Comparables
                  </h3>
                  <h3
                    className="mt-1 text-lg font-medium tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Previously Sold - Same Street
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                  <div className="w-full flex flex-wrap gap-2">
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left gap-5">
                      {property?.results.streetComparable?.map((ele, index) => (
                        <div
                          className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                          style={{ color: getThemes.txtColor }}
                          key={index + "streetComparable"}
                        >
                          <h4 className="align-middle flex flex-wrap gap-x-2">
                            <FontAwesomeIcon icon={faHome} />
                            <b>Address:</b> {ele && <p>{cleanString(ele)}</p>}
                          </h4>
                        </div>
                      ))}
                      {property?.results.streetComparable?.length === 0 && (
                        <>
                          <div
                            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                            style={{ color: getThemes.txtColor }}
                          >
                            <h4 className="align-middle flex flex-wrap gap-x-2">
                              <FontAwesomeIcon icon={faHome} />
                              <b>Address:</b> <p>N/A</p>
                            </h4>
                          </div>
                          <div
                            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                            style={{ color: getThemes.txtColor }}
                          >
                            <h4 className="align-middle flex flex-wrap gap-x-2">
                              <FontAwesomeIcon icon={faHome} />
                              <b>Address:</b> <p>N/A</p>
                            </h4>
                          </div>
                          <div
                            className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                            style={{ color: getThemes.txtColor }}
                          >
                            <h4 className="align-middle flex flex-wrap gap-x-2">
                              <FontAwesomeIcon icon={faHome} />
                              <b>Address:</b> <p>N/A</p>
                            </h4>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <==============below fold without side=================> */}
          <div className="w-full sm:py-2 lg:py-4 xl:py-4">
            <div className="flex max-w-7xl m-auto lg:p-0 p-5">
              <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
                <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }}>
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Comparables
                  </h3>
                  <h3
                    className="mt-1 text-lg font-medium tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}

                  >
                    For Sale - Within The Area
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                  <div className="w-full flex flex-wrap gap-2">
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left gap-5">
                      {property?.results.saleComparable?.map((ele, index) => (
                        <div
                          className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                          style={{ color: getThemes.txtColor }}
                          key={index + "saleComparable"}
                        >
                          <h4 className="align-middle flex flex-wrap gap-x-2 lg:pb-0 pb-5">
                            <FontAwesomeIcon icon={faHome} />
                            <b>Address:</b> {ele && <p>{cleanString(ele)}</p>}
                          </h4>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <==============below fold without side/bg=================> */}
          <div className="w-full sm:py-2 lg:py-4 xl:py-4">
            <div className="flex max-w-7xl m-auto lg:p-0 p-5">
              <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
                <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }}>
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Comparables
                  </h3>
                  <h3
                    className="mt-1 text-lg font-medium tracking-normal text-primarycolor text-left"  style={{ color: getThemes.txtColor }}
                  >
                    For Rent - Within The Area
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                  <div className="w-full flex flex-wrap gap-2">
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left gap-5">
                      {property?.results.rentComparable?.map((ele, index) => (
                        <div
                          className="lg:w-1/3 w-full text-primarycolor font-normal text-base"
                          style={{ color: getThemes.txtColor }}
                          key={index + "rentComparable"}
                        >
                          <h4 className="align-middle flex flex-wrap gap-x-2 lg:pb-0 pb-5">
                            <FontAwesomeIcon icon={faHome} />
                            <b>Address:</b> {ele && <p>{cleanString(ele)}</p>}
                          </h4>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <==============below fold with side=================> */}
          <div className="w-full sm:py-3 lg:py-6 xl:py-6">
            <div className="grid lg:grid-cols-3 sm:grid-cols-1 max-w-7xl m-auto lg:py-5 lg:px-0 p-5 gap-5">
              <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto lg:my-0 my-2 lg:col-span-2">
                <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color1 }}>
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left" style={{ color: getThemes.txtColor }}
                  >
                    Basic property information
                  </h3>
                </div>
                <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                  <div className="w-full flex flex-wrap gap-2">
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHome} />
                          <b>Property type:</b> {property?.results.houseSubtype}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHome} />
                          <b>Strategy:</b> {property?.results.strategy}{" "}
                          {!property?.results.strategy && <span>N/A</span>}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faMoneyCheck} />
                          <b>Current Rent:</b>
                          {property?.results.currentRent && (
                            <span>
                              £
                              {numberFormat(
                                property?.results.currentRent as number
                              )}
                            </span>
                          )}
                          {!property?.results.currentRent &&
                            property!.results.currentRent != 0 && (
                              <span>N/A</span>
                            )}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faBed} />
                          <b>Bedrooms:</b>
                          {property?.results.bedrooms}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faCheckDouble} />
                          <b>Flood risk:</b> {property?.results.floodRisk}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHome} />
                          <b>Property Price:</b> £
                          {numberFormat(property?.results.price as number)}
                        </h4>
                      </div>
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faHome} />
                          <b>Freehold/leasehold:</b>{" "}
                          {property?.results.tenureType}
                        </h4>
                      </div>
                      {/* <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          <b>Occupancy at Completion:</b>{" "}
                          {property?.results.occupancy}
                        </h4>
                      </div> */}
                    </div>
                    <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faExpandArrowsAlt} />
                          <b>Property size:</b> {property?.results.floorsqft}
                        </h4>
                      </div>
                      <div
                        className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                        style={{ color: getThemes.txtColor }}
                      >
                        <h4 className="align-middle gap-x-2 items-center flex">
                          <FontAwesomeIcon icon={faCheckDouble} />
                          <b>EPC Rating:</b> {property?.results.epc}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-gradient-to-br from-primarycolor to-secondarycolor border shadow-sm rounded-xl font-roboto h-min py-4 lg:col-span-1"
                style={{
                  background: `linear-gradient(to bottom,${
                    getThemes && getThemes!.color1
                  },${getThemes && getThemes.color2})`,
                }}
              >
                <div className="rounded-t-xl px-4 md:px-5 pb-4">
                  <h3 className="text-3xl font-semibold tracking-normal text-white-f2f9fa text-left">
                    Important
                  </h3>
                </div>
                <div className="w-full text-white-f2f9fa px-4 font-normal text-base leading-7">
                  <h4 className="text-left pb-5">
                    Due to the high volume of properties secured, we are unable
                    to view all properties before they are available to purchase
                    on our site. If a property's condition is worse than
                    advised, the client will receive a refund or have the
                    opportunity to transfer the fee paid to another property.
                  </h4>
                  {!submitLoading && (
                    <>
                      {!property?.results.reserved && (
                        <button
                        className="w-3/5 m-auto py-3 mt-4 bg-btnscolor rounded-full text-white-f2f9fa hover:bg-white-f2f9fa text-base font-medium transition duration-500 hover:scale-110 mt-10 noprint"
                        data-hs-overlay="#hs-slide-down-animation-modal"
                          style={{ background: getThemes && getThemes.color1 }}
                          onClick={() => submitCheckout()}
                        >
                          Reserve
                        </button>
                      )}

                      {property?.results.reserved && (
                        <button className="w-full m-auto py-3 mt-4 bg-grey-848484 disabled rounded-full text-white-f2f9fa  text-base font-medium cursor-not-allowed noprint">
                          Property Reserved
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <==============below fold with side=================> */}
<div className="w-full sm:py-3 lg:py-6 xl:py-6">
  <div className="flex max-w-7xl m-auto py-5 lg:p-0 p-5">
    <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
      <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5" style={{ backgroundColor: getThemes && getThemes.color2 }}>
        <h3
          className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left"
          style={{ color: getThemes.txtColor }}
        >
          Additional Information – Local Area
        </h3>
      </div>
      <div className="p-3" style={{ backgroundColor: getThemes && getThemes.color2 }}>
        <MapContainer
          center={[
            property?.results.latitude != undefined ? property?.results.latitude : 54.5713726,
            property?.results.longitude != undefined ? property?.results.longitude : -1.2080007,
          ]}
          zoom={20}
          scrollWheelZoom={false}
          style={{ height: 350, width: "100%" }}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/off2on/cl0hxi4ql000115o9535ccwbr/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2ZmMm9uIiwiYSI6ImNrcHM5dTdweTA4YncycG8xbzBxMHlqMWYifQ.QoQslFiXHbvPYZpXavjjew`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            tileSize={512}
            zoomOffset={-1}
          />
          <Marker
            position={[
              property?.results.latitude != undefined ? property?.results.latitude : 54.5713726,
              property?.results.longitude != undefined ? property?.results.longitude : -1.2080007,
            ]}
          >
            {/*<Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>*/}
          </Marker>
        </MapContainer>
      </div>

      <div className="p-4 md:p-5 rounded-b-xl" style={{ backgroundColor: getThemes && getThemes.color2 }}>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 font-roboto text-sm leading-7 text-left">
          <div
            className="text-primarycolor font-normal text-base"
            style={{ color: getThemes.txtColor }}
          >
            <h4 className="align-middle font-medium text-2xl gap-x-2 items-center mb-3">
              Nearest stations
            </h4>
            <div className="font-roboto text-sm py-2 leading-7 w-full text-left">
              <div
                className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                style={{ color: getThemes.txtColor }}
              >
                {property?.results.nearestStation &&
                  property?.results.nearestStation.map(
                    (ele, index) => (
                      <div
                        className="align-middle flex flex-col gap-x-2  mb-4"
                        key={index + "station"}
                      >
                        <div className="flex align-middle mb-1 pb-2 items-center gap-2">
                          <FontAwesomeIcon icon={faBullseye} />
                          <b>{ele.station}</b>
                        </div>
                        <div className="flex align-middle border-t pt-2 items-center gap-2">
                          <FontAwesomeIcon icon={faWalking} />
                          <b>{ele.distance}</b>
                        </div>

                        {property?.results.nearestStation!.length -
                          1 >
                          index && (
                            <>
                              <div className="mt-3"></div>
                            </>
                          )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div
            className="text-primarycolor font-normal text-base"
            style={{ color: getThemes.txtColor }}
          >
            <h4 className="align-middle font-medium text-2xl gap-x-2 items-center">
              Nearest schools
            </h4>
            <div className="lg:flex font-roboto text-sm py-2 leading-7 w-full text-left">
              <div
                className="lg:w-1/2 w-full text-primarycolor font-normal text-base"
                style={{ color: getThemes.txtColor }}
              >
                {property?.results.nearestSchool &&
                  property?.results.nearestSchool.map(
                    (ele, index) => (
                      <div
                        className="align-middle flex flex-col gap-x-2  mb-4"
                        key={index + "station"}
                      >
                        <div className="flex align-middle border-t pt-2 items-center gap-2">
                          <FontAwesomeIcon icon={faBullseye} />
                          <b>{ele.school}</b>
                        </div>
                        <div className="flex align-middle border-t pt-2 items-center gap-2">
                          <FontAwesomeIcon icon={faWalking} />
                          <b>{ele.distance}</b>
                        </div>
                        {property?.results.nearestSchool!.length - 1 >
                          index && (
                            <>
                              <div className="mt-3"></div>
                            </>
                          )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

          <div className="w-full sm:py-3 lg:py-8 xl:py-10">
            <div className="flex max-w-7xl m-auto py-5 lg:p-0 p-5">
              <div className="w-full text-left bg-white border-gray-300 border shadow-sm rounded-xl font-roboto p-5" style={{ backgroundColor: getThemes && getThemes.color2 }}>
                <div className="rounded-t-xl mb-3">
                  <h3
                    className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left"
                    style={{ color: getThemes.txtColor }}
                  >
                    Disclaimer
                  </h3>
                </div>
                <p style={{ color: getThemes.txtColor }} >
                
                  This is for your information only – you shouldn't view this as
                  legal advice, tax advice, investment advice, or any advice at
                  all. This information does not constitute a solicitation,
                  recommendation, endorsement in this or in any other
                  jurisdiction. While we've tried to make sure this information
                  is accurate and up to date, things can change, so it shouldn't
                  be viewed as totally comprehensive. We always recommends you
                  seek out independent advice before making any investment
                  decisions.
                </p>
                <br />
                <p style={{ color: getThemes.txtColor }} >
                  With respect to gross yield & rental income, these values are
                  either provided by our partners or provided by comparing
                  similar properties within the same geographical location.
                  Gross yield is calculated using the standard industry formula
                  by taking the annual rental income of the property and
                  dividing it by the purchase price of the property. Any
                  information pertaining to the EPC has been retrieved via the
                  UK government public database of EPC certificates.
                </p>
                <p style={{ color: getThemes.txtColor }} >
                  Stock listed may on occasion be sold or withdrawn by Vendors
                  at short notice, and thus be no longer available.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Property;
